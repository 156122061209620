<script>
    import Multiselect from 'vue-multiselect'

    export default {
        props: ['routeSearchUri', 'dataDate', 'dataStartLocation', 'dataEndLocation', 'typeMobile'],
        components: {
            Multiselect
        },
        data() {
            return {
                filter: {
                    start_location: null,
                    end_location: null,
                    date: this.$moment().format('DD.MM.YYYY'),
                    loading: true,
                },

                locations: [],

                optionsDate: {
                    format: 'DD.MM.YYYY',
                    useCurrent: false,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    minDate: new Date(),
                    locale: 'ru',
                    icons: {
                        previous: 'icon arrow-point-to-left',
                        next: 'icon arrow-point-to-right',
                    },
                },

                showFrom: false,
                showWhere: false,
                showDate: false,

            }
        },
        watch: {
            'filter.date': function () {
                this.showDate = false;
            },
            'filter.start_location': function () {
                this.showFrom = false;
            },
            'filter.end_location': function () {
                this.showWhere = false;
            },
            showFrom: function () {
                if(this.typeMobile){
                    this.$refs["searchFromMobile"].activate();
                }
            },
            showWhere: function () {
                if(this.typeMobile){
                    this.$refs["searchToMobile"].activate();
                }
            }
        },
        mounted() {
            this.getPopularLocations();
            if (this.dataStartLocation !== undefined) {
                this.filter.start_location = this.dataStartLocation
            }

            if (this.dataEndLocation !== undefined) {
                this.filter.end_location = this.dataEndLocation
            }

            if (this.dataDate !== undefined) {
                this.filter.date = this.dataDate;
            }
            setTimeout(() => {
                if($('.main-banner').length && !this.typeMobile){
                    this.$refs["searchFrom"].activate();
                }
            }, 500);
        },
        methods: {
            swapLocations(){
                let tmp = this.filter.start_location;
                this.filter.start_location = this.filter.end_location;
                this.filter.end_location = tmp;
            },

            notBeforeToday(date) {
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            },

            searchLocations(query) {
                this.filter.loading = true;
                if (query.length > 1) {
                    this.fetchLocations(query);
                }else{
                    this.locations = [];
                }
            },

            fetchLocations(query) {
                axios.get('/api/locations/in-routes/?query=' + query)
                    .then(({data}) => {
                        this.locations = data.locations;
                        this.filter.loading = false;
                    });
            },

            setDate(event) {
                this.filter.date = event.target.dataset.date;
                this.submit();
            },

            getPopularLocations() {
                axios.get('/api/locations/popular')
                    .then(({data}) => {
                        this.locations = data.locations;
                    });
            },


            submit() {
                if(this.filter.start_location != null && this.filter.end_location != null){
                    location.href = this.routeSearchUri + `?from_id=${this.filter.start_location.id}&to_id=${this.filter.end_location.id}&date=${this.filter.date}`;
                }else{
                    location.href = this.routeSearchUri + `?date=${this.filter.date}`;
                }
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

