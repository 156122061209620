<script>
    import BusSchema from "./BusSchema";

    export default {

        props: ['dataRoute', 'oldPhone'],
        components: {
            BusSchema
        },
        data() {
            return {
                places: [],
                phone: '+38 (0'
            }
        },
        mounted() {
            this.$root.$on('places-changed', (places) => {
                this.places = places;
            })

            if (this.oldPhone) {
                this.phone = this.oldPhone;
            }
        },
        computed: {
            cost: function () {
                return this.places.length * (this.dataRoute.price / 100).toFixed(2);
            }
        },
        watch: {
            phone: function (newValue, oldValue) {
                if (newValue == "+3_ (___) ___-__-__") {
                    this.phone = "+38 (0";
                }

                if (newValue == "") {
                    this.phone = "+38 (0";
                }

            },
        }
    }
</script>
