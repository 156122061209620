<template>

</template>

<script>
    export default {
        props: ['busSchema', 'bookedSeats'],
        data() {
            return {
                places: [],
                schema: [],
                reversedSchema: []
            }
        },
        mounted() {
            this.schema = this.busSchema.slice();

            this.reversedSchema = this.busSchema.slice();

            this.reversedSchema.forEach((row, i, array) => {
                array[i] = row.slice().reverse();
            });

        },
        watch: {
            places: function () {
                this.$root.$emit('places-changed', this.places);
            }
        },
        methods: {
            isBooked(seat) {
                return this.bookedSeats.includes(seat.n);
            },

            isNotBookable(seat) {
                return isNaN(+seat.n);
            }
        }
    }
</script>